<template>
   <form
      @submit.prevent="submitForm"
      class="fast-buy-form form"
      id="fast-buy-form"
      action="#"
      novalidate
   >
      <div class="fast-buy-form__inner">
         <app-field
            v-for="field in fields"
            :key="field.name"
            :is-form-submission="isFormSubmission"
            :is-displayed-error="isDisplayedError"
            :settings="field"
            @result-checking="saveResultChecking"
            @form-reset="resetForm"
         />
      </div>

      <app-textarea
         :is-form-submission="isFormSubmission"
         :is-displayed-error="isDisplayedError"
         :settings="textareaData"
         @result-checking="saveResultChecking"
         @form-reset="resetForm"
      />
   </form>
</template>

<script>
   import AppField from '@/components/templates/FormFields/AppField';
   import AppTextarea from '@/components/templates/FormFields/AppTextarea';

   export default {
      name: 'FastBuyForm',

      components: {
         AppField,
         AppTextarea
      },

      inject: ['openModal'],

      data() {
         return {
            isFormSubmission: false,
            isDisplayedError: false,
            fieldsValidationResults: [],

            fields: [
               {
                  name: 'address',
                  type: 'text',
                  validationType: 'Message',
                  placeholder: 'Адрес',
                  params: { minLength: 10, maxLength: null }
               },
               {
                  name: 'Email',
                  type: 'email',
                  validationType: 'Email',
                  placeholder: 'E-mail'
               }
            ],
            textareaData: {
               name: 'comment',
               validationType: 'Message',
               placeholder: 'Комментарий',
               params: { minLength: null, maxLength: 600 }
            },

            modalSettings: {
               name: 'ModalNotification',
               iconName: 'ready',
               message: 'Ваш заказ оформлен!',
               isActive: true
            }
         };
      },

      methods: {
         saveResultChecking(value) {
            const field = this.fieldsValidationResults.find(
               (item) => item.name === value.name && item.type === value.type
            );

            if (field) {
               field.isValid = value.isValid;
            } else {
               this.fieldsValidationResults.push(value);
            }
         },

         resetForm(value) {
            this.isFormSubmission = value;
         },

         submitForm() {
            if (this.isValidFieldValue) {
               this.isFormSubmission = true;
               this.isDisplayedError = false;

               setTimeout(() => {
                  this.openModal(this.modalSettings);
               }, 200);
            } else {
               this.isDisplayedError = true;
            }
         }
      },

      computed: {
         isValidFieldValue() {
            return (
               this.fieldsValidationResults.length &&
               this.fieldsValidationResults.every((item) => item.isValid)
            );
         }
      }
   };
</script>
